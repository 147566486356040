import React from 'react';
import TopLeftNoteViewer from '../components/TopLeftNoteViewer';
import TopRightUserInfo from '../components/TopRightUserInfo';
import BottomLeftNoteTaker from '../components/BottomLeftNoteTaker';
import BottomRightTextViewer from '../components/BottomRightTextViewer';
import '../css/LoggedIn.css'; // Import the CSS file

function LoggedIn() {
  return (
    <div className="logged-in-container">
      <div className="quadrant">
        <TopLeftNoteViewer />
      </div>
      <div className="quadrant">
        <BottomLeftNoteTaker /> 
      </div>
      <div className="quadrant">
        <TopRightUserInfo />
      </div>
      <div className="quadrant">
        <BottomRightTextViewer />
      </div>
    </div>
  );
}

export default LoggedIn;
