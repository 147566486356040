import React, { useState, useEffect, useRef } from 'react';
import { useNote } from '../context/NoteContext';
import { copyToClipboard, saveAsTextFile, downloadAsPdf } from '../utils/bottomRightUtils';
import ProcessModal from './ProcessModal';
import ReactMarkdown from 'react-markdown';
import '../css/BottomRightTextViewer.css';

function BottomRightTextViewer() {
  const { latestNote } = useNote();
  const [editableNote, setEditableNote] = useState('');
  const [isEditing, setIsEditing] = useState(false); // Track if the entire text area is in edit mode
  const [isModalOpen, setIsModalOpen] = useState(false);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (latestNote) {
      setEditableNote(latestNote);
    }
  }, [latestNote]);

  // Toggle edit mode on click
  const handleToggleEdit = () => {
    setIsEditing(true);
  };

  // Handle save and exit edit mode
  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleProcessClick = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="text-viewer-container">
      <h2 className="text-viewer-header">Note Viewer</h2>
      <div className="text-viewer-markdown" onClick={handleToggleEdit}>
        {isEditing ? (
          <textarea
            ref={textareaRef}
            value={editableNote}
            onChange={(e) => setEditableNote(e.target.value)}
            onBlur={handleBlur}
            className="text-viewer-textarea"
            rows="10"
            autoFocus
          />
        ) : (
          <ReactMarkdown>{editableNote}</ReactMarkdown>
        )}
      </div>
      <div className="text-viewer-buttons">
        <button onClick={() => copyToClipboard(editableNote)}>Copy to Clipboard</button>
        <button onClick={() => saveAsTextFile(editableNote)}>Save as .txt</button>
        <button onClick={() => downloadAsPdf(editableNote)}>Download as PDF</button>
        <button
          onClick={handleProcessClick}
          disabled={!editableNote.trim()}
        >
          Process
        </button>
        
      </div>
      {isModalOpen && <ProcessModal onClose={() => setIsModalOpen(false)} noteContent={editableNote} />}
    </div>
  );
}

export default BottomRightTextViewer;
