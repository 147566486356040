import React, { useState, useEffect } from 'react';
import { logout } from '../utils/logoutUtils';
import { fetchUserInfo } from '../utils/userInfoUtils';
import '../css/TopRightUserInfo.css';
import OrganizationModal from './OrganizationModal';
import TierModal from './TierModal';
import PersonalInformationModal from './PersonalInformationModal'; // Import the new modal component

function TopRightUserInfo() {
  const [email, setEmail] = useState('');
  const [userTier, setUserTier] = useState('');
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTierModalOpen, setIsTierModalOpen] = useState(false);
  const [isPersonalInfoModalOpen, setIsPersonalInfoModalOpen] = useState(false); // State for Personal Info Modal

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const { email, tier } = await fetchUserInfo();
        setEmail(email);
        setUserTier(tier);
      } catch (error) {
        setEmail('Error fetching email');
        setUserTier('Error fetching tier');
      } finally {
        setLoading(false);
      }
    };
    getUserInfo();
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openTierModal = () => setIsTierModalOpen(true);
  const closeTierModal = () => setIsTierModalOpen(false);
  const openPersonalInfoModal = () => setIsPersonalInfoModalOpen(true); // Open Personal Info Modal
  const closePersonalInfoModal = () => setIsPersonalInfoModalOpen(false); // Close Personal Info Modal

  return (
    <div className="user-info-container">
      <h2>User Information</h2>
      
      <div className="user-info-email">
        <p>Email: <span className="email-placeholder">
          {loading ? 'Loading...' : email}
        </span></p>
        <p>Tier: <span className="tier-placeholder">
          {loading ? 'Loading...' : userTier}
        </span></p>
      </div>

      {/* Button Group */}
      <div className="button-group">
        <button className="manage-org-button" onClick={openModal}>
          Manage Organization
        </button>

        <button className="tier-button" onClick={openTierModal}>
          View Usage Tiers
        </button>

        <button className="personal-info-button" onClick={openPersonalInfoModal}>
          Personal Information
        </button>

        <button className="logout-button" onClick={logout}>Logout</button>
      </div>

      {/* Modals */}
      {isModalOpen && <OrganizationModal isOpen={isModalOpen} onClose={closeModal} />}
      {isTierModalOpen && <TierModal isOpen={isTierModalOpen} onClose={closeTierModal} />}
      {isPersonalInfoModalOpen && <PersonalInformationModal isOpen={isPersonalInfoModalOpen} onClose={closePersonalInfoModal} />}
    </div>
  );
}

export default TopRightUserInfo;
