import React, { useState } from 'react';
import { sendLoginLink } from '../utils/loginUtils';
import '../css/Login.css';  // Importing the CSS file

function Login() {
  const [email, setEmail] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);

  const handleSendLink = async () => {
    try {
      const message = await sendLoginLink(email);
      alert(message);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="login-container">
      <div className="image-section">
        {/* Placeholder for image */}
        <img src="/flipped_doctor_image.webp" alt="NoteSmart" />
      </div>

      <div className="form-section">
        <h1>Welcome to SmartNoteDoctor</h1>
        <p>Create patient notes the smart way.</p>
        <div className="input-container">
          <label>
            Email:
            <input
              type="email"
              placeholder="email@provider.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => setShowTooltip(true)}
              onBlur={() => setShowTooltip(false)}
            />
          </label>
          {/* Tooltip */}
          {showTooltip && (
            <div className="tooltip">
              Enter your email to receive a magic link for signing in or signing up.
            </div>
          )}
        </div>
        <button onClick={handleSendLink}>Send Login/Sign-Up Link</button>
      </div>
    </div>
  );
}

export default Login;
