
export const sendLoginLink = async (email) => {
  try {
    const response = await fetch('/send-login-link', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    // Check if the response is not OK
    if (!response.ok) {
      // Try to parse the error message from the server response
      const errorData = await response.json();
      throw new Error(errorData.error || 'Network response was not ok');
    }

    // Parse the successful response
    const data = await response.json();
    return data.message;
  } catch (error) {
    // Log the error to the console and rethrow it
    console.error('There was a problem with the fetch operation:', error);
    throw new Error(error.message || 'Failed to send login link. Please try again.');
  }
};


// Function to check token validity by calling the backend
export const checkToken = async () => {
  try {
    const response = await fetch('/auth/check-token', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',  // Ensures cookies are included in the request
    });

    if (!response.ok) {
      throw new Error('Token validation failed');
    }

    const data = await response.json();
    return data.isAuthenticated;
  } catch (error) {
    console.error('Error checking token:', error);
    return false;
  }
};
