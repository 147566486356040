export const logout = async () => {
  try {
    await fetch('/auth/logout', {
      method: 'POST',
      credentials: 'include', // Include cookies in the request
    });

    // Refresh the page to trigger the useEffect in App.js
    window.location.reload();
  } catch (error) {
    console.error('Error logging out:', error);
  }
};
