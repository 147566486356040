// Function to fetch organization members
export const fetchOrganizationMembers = async () => {
  try {
    const response = await fetch('/get-organization', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Ensure cookies are included in the request
    });

    if (!response.ok) {
      throw new Error('Failed to fetch organization members');
    }

    const data = await response.json();
    return data.members; // Return only the members array
  } catch (error) {
    console.error('Error fetching organization members:', error);
    throw error;
  }
};

// Function to validate email with basic regex
export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// Function to send an invite link to an email address
export const sendInviteLink = async (email) => {
  try {
    // Validate the email address
    if (!validateEmail(email)) {
      throw new Error('Please enter a valid email address');
    }

    // Send the invite
    const response = await fetch('/send-invite', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Include cookies in the request
      body: JSON.stringify({ email })
    });

    if (!response.ok) {
      throw new Error('Failed to send invite');
    }

    const data = await response.json();
    return data.message;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Function to remove a user from the organization
export const removeUserFromOrganization = async (targetEmail) => {
  try {
    const response = await fetch('/remove-from-organization', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Include cookies in the request
      body: JSON.stringify({ email: targetEmail })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Failed to remove user: ${errorData.error}`);
    }

    const data = await response.json();
    console.log(data.message);
  } catch (error) {
    console.error('Error:', error.message);
  }
};
