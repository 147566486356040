import React, { useState, useEffect } from 'react';
import { fetchOrganizationMembers, sendInviteLink, removeUserFromOrganization } from '../utils/organizationUtils'; // Import utility function
import '../css/OrganizationModal.css'; // Import CSS for modal styling

function OrganizationModal({ isOpen, onClose }) {
  const [members, setMembers] = useState([]); // Initialize with an empty array
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [showAddMember, setShowAddMember] = useState(false); // Show Add Member form
  const [newMemberEmail, setNewMemberEmail] = useState(''); // State for new member email
  const [emailError, setEmailError] = useState(null); // Email validation error state

  useEffect(() => {
    if (isOpen) {
      const loadOrganizationMembers = async () => {
        setLoading(true); // Set loading to true when the modal opens
        try {
          const membersList = await fetchOrganizationMembers();
          setMembers(Array.isArray(membersList) ? membersList : []); // Ensure it's an array
          setError(null); // Clear any previous errors
        } catch (error) {
          setError('Error fetching organization members');
          setMembers([]); // Set members to an empty array in case of error
        } finally {
          setLoading(false); // Stop loading once fetch is complete
        }
      };

      loadOrganizationMembers();
    }
  }, [isOpen]);

  const handleRemove = async (email) => {
    try {
      // Call the function that sends the request to remove the user
      const response = await removeUserFromOrganization(email);

      // If the request was successful (response was OK), update the members list
      if (response.ok) {
        setMembers(members.filter((member) => member !== email));
        console.log(`Successfully removed ${email}`);
      } else {
        // If the server returns an error, handle it without updating the UI
        const errorData = await response.json();
        alert(`Failed to remove user: ${errorData.error}`);
        console.error('Error removing user:', errorData.error);
      }
    } catch (error) {
      // Handle unexpected errors
      alert(`Error removing user: ${error.message}`);
      console.error('Error removing user:', error.message);
    }
  };

  const handleAddMemberClick = () => {
    setShowAddMember(true); // Show the Add Member form
  };

  const handleEmailInputChange = (e) => {
    setNewMemberEmail(e.target.value);
    setEmailError(null); // Clear any previous error on new input
  };

  const validateEmail = (email) => {
    // Basic email regex validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSendInvite = async () => {
    try {
      // Call the utility function to handle everything
      const message = await sendInviteLink(newMemberEmail);

      console.log('Invite sent:', message);

      // Reset the form after success
      setNewMemberEmail('');
      setShowAddMember(false);
    } catch (error) {
      // Handle any errors that occur in the utility function
      setEmailError(error.message);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Manage Organization</h2>

        {error && <p className="error-message">{error}</p>} {/* Show error if any */}

        <table className="members-table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="2">Loading...</td>
              </tr>
            ) : (
              members.length > 0 ? (
                members.map((email) => (
                  <tr key={email}>
                    <td>{email}</td>
                    <td>
                      <button
                        className="remove-button"
                        onClick={() => handleRemove(email)}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2">No members found</td>
                </tr>
              )
            )}
          </tbody>
        </table>

        {/* Add member form */}
        {showAddMember ? (
          <div className="add-member-form">
            <input
              type="text"
              value={newMemberEmail}
              onChange={handleEmailInputChange}
              placeholder="Enter email address"
              className="email-input"
            />
            <button className="send-invite-button" onClick={handleSendInvite}>
              Send Invite Link (practice tier required)
            </button>
            {emailError && <p className="error-message">{emailError}</p>}
          </div>
        ) : (
          <button className="add-member-button" onClick={handleAddMemberClick}>
            + Add Member
          </button>
        )}

        {/* Close modal button */}
        <button className="close-button-org-modal" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
}

export default OrganizationModal;
