// userInfoUtils.js

// Function to fetch user email and usage tier from the server
export const fetchUserInfo = async () => {
    try {
        const response = await fetch('/get-user-info', { // Ensure the endpoint can return both email and tier
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include', // Ensure cookies are sent with the request
        });

        if (!response.ok) {
            throw new Error('Failed to fetch user info');
        }

        const data = await response.json();
        return {
            email: data.email, // Assuming the response contains an "email" field
            tier: data.tier,   // Assuming the response contains a "tier" field
        };
    } catch (error) {
        console.error('Error fetching user info:', error);
        throw error;
    }
};
