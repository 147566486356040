// utils/ProcessFunctionsUtil.js

// Generic helper function to initiate a process
const initiateProcess = async (processType, noteContent, additionalInformation, isBlob = false) => {
  try {
    const response = await fetch('/process-functions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Ensures cookies are sent with the request
      body: JSON.stringify({
        process_type: processType,
        noteContent,
        additionalInformation,
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to initiate ${processType} process`);
    }

    // Return a blob for processes requiring a file download
    if (isBlob) {
      return await response.blob();
    }

    return await response.json();
  } catch (error) {
    console.error(`Error processing ${processType}:`, error);
    throw error;
  }
};

// Function to initiate a Treatment-Plan Letter process
export const initiateTreatmentPlanLetter = (noteContent, additionalInformation) =>
  initiateProcess('treatment-plan-letter', noteContent, additionalInformation);

// Function to initiate a Prescription process (with file download)
export const initiatePrescription = async (noteContent, additionalInformation) => {
  const blob = await initiateProcess('prescription', noteContent, additionalInformation, true);

  // Create a URL for the blob and download it
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'prescription_document.pdf'; // Specify the desired file name
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};

// Function to initiate a Consultation Letter process
export const initiateConsultationLetter = (noteContent, additionalInformation) =>
  initiateProcess('consultation-letter', noteContent, additionalInformation);

// Function to initiate a Referral Letter process
export const initiateReferralLetter = (noteContent, additionalInformation) =>
  initiateProcess('referral-letter', noteContent, additionalInformation);

// Function to initiate a SOAP Note process
export const initiateSoapNote = (noteContent, additionalInformation) =>
  initiateProcess('soap-note', noteContent, additionalInformation);

// Function to initiate a Discharge Summary process
export const initiateDischargeSummary = (noteContent, additionalInformation) =>
  initiateProcess('discharge-summary', noteContent, additionalInformation);

// Function to initiate a Specialty-Specific Template process
export const initiateSpecialtyTemplate = (noteContent, additionalInformation) =>
  initiateProcess('specialty-template', noteContent, additionalInformation);
