import React, { useState } from 'react';
import '../css/ProcessModal.css';
import {
  initiateTreatmentPlanLetter,
  initiatePrescription,
  initiateReferralLetter,
  initiateConsultationLetter,
  initiateSoapNote,
  initiateDischargeSummary,
  initiateSpecialtyTemplate
} from '../utils/ProcessFunctionUtils';

function ProcessModal({ onClose, noteContent }) {
  const [additionalInformation, setAdditionalInformation] = useState('');
  const [loading, setLoading] = useState({
    treatmentPlan: false,
    prescription: false,
    referral: false,
    consultation: false,
    soap: false,
    discharge: false,
    specialty: false
  });

  // Handler for Treatment-Plan Letter button
  const handleTreatmentPlanLetterClick = async () => {
    setLoading(prev => ({ ...prev, treatmentPlan: true }));
    try {
      const response = await initiateTreatmentPlanLetter(noteContent, additionalInformation);
      console.log('Treatment-Plan Letter response:', response);
      alert("Treatment-Plan Letter generated successfully. Please refresh your document viewer to see the generated document.");
    } catch (error) {
      console.error('Error initiating Treatment-Plan Letter:', error);
    } finally {
      setLoading(prev => ({ ...prev, treatmentPlan: false }));
    }
  };

  // Handler for Create Prescription button
  const handleCreatePrescriptionClick = async () => {
    setLoading(prev => ({ ...prev, prescription: true }));

    try {
      // Commented out the prescription functionality
      /*
      const response = await initiatePrescription(noteContent, additionalInformation);
      console.log('Prescription response:', response);
      alert("Prescription document downloaded successfully.");
      */
    } catch (error) {
      // console.error('Error initiating Prescription:', error);
    } finally {
      setLoading(prev => ({ ...prev, prescription: false }));
    }
  };


  // Handler for Referral Letter button
  const handleReferralLetterClick = async () => {
    setLoading(prev => ({ ...prev, referral: true }));
    try {
      const response = await initiateReferralLetter(noteContent, additionalInformation);
      console.log('Referral Letter response:', response);
      alert("Referral Letter generated successfully. Please refresh your document viewer to see the generated document.");
    } catch (error) {
      console.error('Error initiating Referral Letter:', error);
    } finally {
      setLoading(prev => ({ ...prev, referral: false }));
    }
  };

  // Handler for Consultation Letter button
  const handleConsultationLetterClick = async () => {
    setLoading(prev => ({ ...prev, consultation: true }));
    try {
      const response = await initiateConsultationLetter(noteContent, additionalInformation);
      console.log('Consultation Letter response:', response);
      alert("Consultation Letter generated successfully. Please refresh your document viewer to see the generated document.");
    } catch (error) {
      console.error('Error initiating Consultation Letter:', error);
    } finally {
      setLoading(prev => ({ ...prev, consultation: false }));
    }
  };

  // Handlers for the new buttons
  const handleSoapNoteClick = async () => {
    setLoading(prev => ({ ...prev, soap: true }));
    try {
      console.log('clicked')
      const response = await initiateSoapNote(noteContent, additionalInformation);
      console.log('SOAP Note response:', response);
      alert("SOAP Note generated successfully.");
    } catch (error) {
      console.error('Error initiating SOAP Note:', error);
    } finally {
      setLoading(prev => ({ ...prev, soap: false }));
    }
  };

  const handleDischargeSummaryClick = async () => {
    setLoading(prev => ({ ...prev, discharge: true }));
    try {
      const response = await initiateDischargeSummary(noteContent, additionalInformation);
      console.log('Discharge Summary response:', response);
      alert("Discharge Summary generated successfully.");
    } catch (error) {
      console.error('Error initiating Discharge Summary:', error);
    } finally {
      setLoading(prev => ({ ...prev, discharge: false }));
    }
  };

  const handleSpecialtyTemplateClick = async () => {
    setLoading(prev => ({ ...prev, specialty: true }));
    try {
      const response = await initiateSpecialtyTemplate(noteContent, additionalInformation);
      console.log('Specialty-Specific Template response:', response);
      alert("Specialty-Specific Template generated successfully.");
    } catch (error) {
      console.error('Error initiating Specialty-Specific Template:', error);
    } finally {
      setLoading(prev => ({ ...prev, specialty: false }));
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Process Note</h2>
          <button onClick={onClose} className="close-button-process-modal">Close</button>
        </div>

        <div className="modal-body">
          <div className="modal-left">
            <p>Click on an option below to create a document. Your note on the right will be converted to the selected type. Some options may require additional information for best outcomes.</p>

            <div className="letter-section">
              <h3>Letters</h3>
              <button
                className="modal-button referral-letter-button"
                onClick={handleReferralLetterClick}
                disabled={loading.referral}
              >
                {loading.referral ? "Processing..." : "Referral Letter"}
              </button>
              <button
                className="modal-button follow-up-letter-button"
                onClick={handleTreatmentPlanLetterClick}
                disabled={loading.treatmentPlan}
              >
                {loading.treatmentPlan ? "Processing..." : "Treatment-Plan Letter"}
              </button>
              <button
                className="modal-button consultation-letter-button"
                onClick={handleConsultationLetterClick}
                disabled={loading.consultation}
              >
                {loading.consultation ? "Processing..." : "Consultation Letter"}
              </button>
            </div>

            <div className="prescription-section">
              <h3>Prescriptions</h3>
              <button
                className="modal-button prescription-button"
                onClick={handleCreatePrescriptionClick}
                disabled={loading.prescription}
              >
                {loading.prescription ? "Processing..." : "Coming Soon..."}
              </button>
            </div>

            <div className="template-section">
              <h3>Template Conversion</h3>
              <button
                className="modal-button soap-template-button"
                onClick={handleSoapNoteClick}
                disabled={loading.soap}
              >
                {loading.soap ? "Processing..." : "SOAP Note"}
              </button>
              <button
                className="modal-button discharge-template-button"
                onClick={handleDischargeSummaryClick}
                disabled={loading.discharge}
              >
                {loading.discharge ? "Processing..." : "Discharge Summary"}
              </button>
              <button
                className="modal-button specialty-template-button"
                onClick={handleSpecialtyTemplateClick}
                disabled={loading.specialty}
              >
                {loading.specialty ? "Processing..." : "Specialty-Specific Template"}
              </button>
            </div>
          </div>

          <div className="modal-right">
            <textarea
              value={noteContent}
              readOnly
              className="modal-textarea"
            />
            <textarea
              value={additionalInformation}
              onChange={(e) => setAdditionalInformation(e.target.value)}
              placeholder="Enter additional information, e.g., patient info, pharmacy address"
              className="additional-info-textarea"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcessModal;
