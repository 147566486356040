import React, { useState, useEffect } from 'react';
import './App.css';
import Login from './pages/Login';
import LoggedIn from './pages/LoggedIn';
import { checkToken } from './utils/loginUtils';  // Import the new server-based check function
import { NoteProvider } from './context/NoteContext';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Extract token from URL if present
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      // Redirect to home without token in URL
      window.history.replaceState({}, document.title, "/");
    }

    // Use the server-based check to verify if the user is logged in
    checkToken().then(isAuthenticated => {
      setIsLoggedIn(isAuthenticated);
    });
  }, []);

  return (
    <NoteProvider>
      <div className="App">
        {isLoggedIn ? <LoggedIn /> : <Login />}
      </div>
    </NoteProvider>
  );
}

export default App;
