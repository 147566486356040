// PersonalInformationUtils.js

// Function to fetch doctor (user), practice, and template information from the server
export const fetchPersonalInformation = async () => {
  try {
    const response = await fetch('/get-user-info', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Ensure cookies are sent with the request
      body: JSON.stringify({ email: 'user@example.com' }), // Replace with dynamic email if needed
    });

    if (!response.ok) {
      throw new Error('Failed to fetch personal information');
    }

    const data = await response.json();
    return {
      user_info: data.user_info,
      practice_info: data.practice_info,
      template_info: data.template_info, // Include template_info
    };
  } catch (error) {
    console.error('Error fetching personal information:', error);
    throw error;
  }
};

// Function to update doctor (user), practice, and template information on the server
export const updatePersonalInformation = async (user_info, practice_info, template_info) => {
  try {
    const response = await fetch('/update-user-info', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Ensure cookies are sent with the request
      body: JSON.stringify({ user_info, practice_info, template_info }), // Include template_info in the request body
    });

    if (!response.ok) {
      throw new Error('Failed to update personal information');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating personal information:', error);
    throw error;
  }
};
