import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import ReactMarkdown from 'react-markdown';
import ReactDOMServer from 'react-dom/server';

// Function to copy text to the clipboard
export const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    alert('Note copied to clipboard!');
  } catch (err) {
    console.error('Failed to copy text: ', err);
  }
};

// Function to save text as a .txt file
export const saveAsTextFile = (text, filename = 'note.txt') => {
  const element = document.createElement('a');
  const file = new Blob([text], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
  document.body.removeChild(element);
};

export async function downloadAsPdf(markdownContent) {
  // Convert Markdown to HTML
  const htmlContent = ReactDOMServer.renderToStaticMarkup(
    <ReactMarkdown>{markdownContent}</ReactMarkdown>
  );

  // Create an off-screen container for rendering the HTML content
  const container = document.createElement('div');
  container.innerHTML = htmlContent;
  container.style.position = 'absolute';
  container.style.top = '-10000px'; // Position off-screen
  container.style.left = '-10000px';
  container.style.width = '190mm'; // Set to A4 page width minus margins in mm
  container.style.padding = '10mm'; // Add padding for better spacing
  container.style.boxSizing = 'border-box'; // Ensure padding is included in the width
  document.body.appendChild(container);

  try {
    // Capture the container as a canvas using html2canvas
    const canvas = await html2canvas(container, { scale: 2 });

    const imgData = canvas.toDataURL('image/png');

    // Initialize jsPDF
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Calculate dimensions to preserve aspect ratio
    const canvasWidthInMm = (canvas.width * 25.4) / 96; // Convert canvas width from pixels to mm
    const canvasHeightInMm = (canvas.height * 25.4) / 96; // Convert canvas height from pixels to mm
    const aspectRatio = canvasHeightInMm / canvasWidthInMm;

    const scaledWidth = pdfWidth - 20; // Leave a 10mm margin on each side
    const scaledHeight = scaledWidth * aspectRatio; // Calculate height based on aspect ratio

    let position = 0;

    // Add pages to fit the full content with preserved aspect ratio
    while (position < scaledHeight) {
      const remainingHeight = scaledHeight - position;
      const currentHeight = Math.min(pdfHeight - 20, remainingHeight); // Account for top/bottom margins

      pdf.addImage(imgData, 'PNG', 10, -position + 10, scaledWidth, scaledHeight, '', 'FAST');

      position += pdfHeight - 20;
      if (position < scaledHeight) {
        pdf.addPage();
      }
    }

    pdf.save('note.pdf');
  } catch (error) {
    console.error('Error generating PDF:', error);
  } finally {
    // Cleanup the container
    document.body.removeChild(container);
  }
}
