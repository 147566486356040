// Function to fetch all notes for the current user
export const fetchUserNotes = async () => {
  try {
    const response = await fetch('/get-all-notes', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Ensures cookies are sent with the request
    });

    if (!response.ok) {
      throw new Error('Failed to fetch notes');
    }

    const notes = await response.json();
    return notes;
  } catch (error) {
    console.error('Error fetching notes:', error);
    throw error;
  }
};
