import React, { useState, useEffect } from 'react';
import { fetchUserNotes } from '../utils/noteFetchUtils'; // Import the utility
import { useNote } from '../context/NoteContext'; // To update the note context
import '../css/TopLeftNoteViewer.css'; 
import { FaSync } from 'react-icons/fa'; // Import FontAwesome sync icon (if using react-icons)

function TopLeftNoteViewer() {
  const [notes, setNotes] = useState([]); // Store fetched notes
  const { setLatestNote } = useNote(); // Get the context to update the selected note

  // Function to fetch and update notes
  const fetchAndSetNotes = async () => {
    try {
      const fetchedNotes = await fetchUserNotes(); // Fetch the notes from the server
      setNotes(fetchedNotes);
    } catch (error) {
      console.error('Failed to fetch notes:', error);
    }
  };

  // Set up initial fetch and interval-based refresh on component mount
  useEffect(() => {
    fetchAndSetNotes(); // Fetch notes on initial render

    const intervalId = setInterval(() => {
      fetchAndSetNotes(); // Fetch notes every 8 seconds
    }, 8000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Function to handle clicking on a note row
  const handleNoteClick = (note) => {
    setLatestNote(note.note); // Update the context with the selected note text, not the entire object
  };

  // Utility to truncate note text for table display
  const truncateText = (text, maxLength = 30) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <div className="top-left-note-viewer-container">
      <div className="top-left-header">
        <h2>Document Viewer</h2>

        {/* Refresh icon with tooltip */}
        <div className="refresh-icon-container" onClick={fetchAndSetNotes}>
          <FaSync className="refresh-icon" />
          <span className="tooltip-text">Refresh Documents</span>
        </div>
      </div>

      <div className="notes-container">
        <table>
          <thead>
            <tr>
              <th>Doc</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {notes.map((note, index) => (
              <tr key={index} onClick={() => handleNoteClick(note)}>
                <td>{truncateText(note.note)}</td>
                <td>{new Date(note.created_at).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TopLeftNoteViewer;
