import React, { useState, useEffect } from 'react';
import '../css/PersonalInformationModal.css';
import { fetchPersonalInformation, updatePersonalInformation } from '../utils/PersonalInformationUtils';

function PersonalInformationModal({ isOpen, onClose }) {
  const [doctorInfo, setDoctorInfo] = useState('');
  const [practiceInfo, setPracticeInfo] = useState('');
  const [templateInfo, setTemplateInfo] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isOpen) {
      loadPersonalInformation();
    }
  }, [isOpen]);

  const loadPersonalInformation = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await fetchPersonalInformation();
      setDoctorInfo(data.user_info || '');
      setPracticeInfo(data.practice_info || '');
      setTemplateInfo(data.template_info || '');
    } catch (err) {
      setError('Failed to load personal information.');
    } finally {
      setLoading(false);
    }
  };


  const handleSave = async () => {
    setLoading(true);
    setError(null);
    try {
      await updatePersonalInformation(doctorInfo, practiceInfo, templateInfo); // Include templateInfo here
      onClose(); // Close modal after successful save
    } catch (err) {
      setError('Failed to save personal information.');
    } finally {
      setLoading(false);
    }
  };


  if (!isOpen) return null;

  return (
    <div className="personal-info-modal-overlay">
      <div className="personal-info-modal-content">
        <h2>Personal Information</h2>

        {error && <p className="error-message">{error}</p>}

        <div className="personal-info-input-group">
          <div className="doctor-info">
            <label className="personal-info-label" htmlFor="doctor-info-input">
              Doctor Information
            </label>
            <textarea
              id="doctor-info-input"
              className="personal-info-textarea doctor-info-textarea"
              value={doctorInfo}
              onChange={(e) => setDoctorInfo(e.target.value)}
              placeholder="Dr. Jane Smith"
            />
          </div>

          <div className="practice-info">
            <label className="personal-info-label" htmlFor="practice-info-input">
              Practice Information
            </label>
            <textarea
              id="practice-info-input"
              className="personal-info-textarea practice-info-textarea"
              value={practiceInfo}
              onChange={(e) => setPracticeInfo(e.target.value)}
              placeholder="456 High St, London, UK"
            />
          </div>

          <div className="template-info">
            <label className="personal-info-label" htmlFor="template-info-input">
              Template Information
            </label>
            <textarea
              id="template-info-input"
              className="personal-info-textarea template-info-textarea"
              value={templateInfo}
              onChange={(e) => setTemplateInfo(e.target.value)}
              placeholder="SOAP note..."
            />
          </div>

        </div>

        <div className="personal-info-modal-buttons">
          <button className="personal-info-save-button" onClick={handleSave} disabled={loading}>
            {loading ? 'Saving...' : 'Save'}
          </button>
          <button className="personal-info-close-button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default PersonalInformationModal;
